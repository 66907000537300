import React from "react"
import "./navbar.css";
function Navbar() {
        return (
            <div>
                    <nav className="nav">
                        <a className="nav-logo-text" href="/">
                            &#60;SeanRoganDev&#62;
                        </a>
                        <ul>
                                <NavLink href="/projects">Projects</NavLink>
                                <NavLink href="/cv">CV</NavLink>
                        </ul>
                    </nav>
            </div>
        )
    }

    function NavLink({href, children, ...props }) {
    //the pathname of the current window the browser is on
    const path = window.location.pathname
        return(
            //adds 'active' class name to which ever navlink href matches current path.
            // if on cv page, navlink with href="/cv" will be set to active
            <li className={path === href ? "active" : ""}>
                <a href={href} {...props}>
                    {children}
                </a>
            </li>
        )


    }

export default Navbar