import React from "react"
import { render } from "react-dom"
import App from "./App";
import { createBrowserHistory} from "history";


 function Page() {
     return (
         <div>
             <App/>
         </div>
     )
 }
//changes url to
const history = createBrowserHistory;



let app = document.getElementById('root');
if (app) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
    // eslint-disable-next-line no-restricted-globals
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
        //history.replace(path);
        history.toString().replace(path);
    }
    render(<Page/>, app);
}