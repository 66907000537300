import "./projects-carousel.css";
const PEAK_CONDITIONS_LIVE_DEMO_LINK = "https://peak-conditions-api.herokuapp.com/";
const PEAK_CONDITIONS_GITHUB_REPO_LINK = "https://github.com/SeanRogan/MountainWeatherApi";
const PEAK_CONDITIONS_RAPID_API_LINK = "https://rapidapi.com/SeanRogan/api/peak-conditions/";
export default
function ProjectsCarousel() {

    return <div className="body" id="projects-carousel__container">
        <div className="wrapper" id="peak-conditions-project-card">
            <div className="project-card">

                    <h1 id="peak-conditions-project-card__title">Peak Conditions API</h1>
                    <h2 id="peak-conditions-project-card__summary-header">A RESTful API written in Java with Spring-boot, a PostgreSQL database, deployed with Heroku.</h2>
                    <h2 id="peak-conditions-project-card__summary-body">Mountain-weather.com is a useful website, but an old website that lacks an API. So i built one. The API controls a web-scraper, which collects weather data for the chosen mountain peak. The service layer processes the data into a weather report which is returned as a JSON object.</h2>
                    <h3 id="peak-conditions-project-card__stack-title">The full stack: </h3>
                    <h3 id="peak-conditions-project-card__stack-content">Java, Spring-Boot, Spring Security, PostgreSQL, H2 Database(for testing), Spring data JPA, Jsoup, Gson, Log4j, SLF4j, Lombok, JUnit, AssertJ, Mockito, Selenium WebDriver, Heroku </h3>

                    <a href={PEAK_CONDITIONS_GITHUB_REPO_LINK} className="project-link__github" id="peak-conditions-project-link__github">
                        <h2>View the project's github repository</h2>
                    </a>
                    <a href={PEAK_CONDITIONS_LIVE_DEMO_LINK} className="project-link__demo" id="peak-conditions-project-link__demo">
                        <h2>View the live demo and documentation page</h2>
                    </a>
                    <a href={PEAK_CONDITIONS_RAPID_API_LINK} className="project-link__other" id="peak-conditions-project-link__rapid-api">
                        <h2>Peak Conditions on the Rapid API Hub</h2>
                    </a>

            </div>
        </div>
    </div>

}
