import "./cvpage.css";
export default function CVPage() {

    return <div className="container">
        <div className= "cv-container">

                <h4 className="cv-contact-info">Contact Info<br></br>
                Phone: 857-329-2893<br></br>
                Email: Srogan88@Gmail.com</h4>

                <h1 className="cv-header">
                    Sean Rogan - Software Developer
                </h1>
                <h4 className="cv-summary">
                    I am a self taught developer, with a focus on back-end engineering.
                    I have been writing programs with Java, in a macOS environment, for 2 years. I am seeking a junior level role where I can put my passions to work,
                    while continuing to learn and develop my skills.
                    Most of my experience is with Java and the Spring Boot framework.
                    I also have some experience with front end technologies like HTML, CSS, JavaScript, React, Thymeleaf, Bootstrap
                    and relational databases such as MySQL and PostgreSQL.
                    I have experience with testing APIs with Postman as well as unit and integration testing with Junit and Mockito.
                    I am familiar with web technologies and protocols like HTTP and Oauth, using JWT for authorization/authentication, interacting with 3rd party APIs,
                    processing Json data for use in an application.
                </h4>


            <div className="cv-tech-experience-container">
            <div className="cv-tech-experience-header">
                <h3>Technology Experience</h3>
                <h4 className="cv-tech-experience-body">
                    Java, Spring/SpringBoot, MySQL, PostgreSQL, SQLite,
                    JDBC, Spring data JPA, Hibernate,Spring security, HTML, CSS, BootStrap, JavaScript,
                    React, Log4J/SLF4J, Junit, Mockito, ThymeLeaf, Git/Github, XML, JSON
                </h4>
            </div>
            </div>
            <div className='cv-tools-experience-container'>
            <div className="cv-tools-experience-header">
                <h3 className="cv-tools-header">Tools</h3>
                <h4 className="cv-tools-experience-body">
                    Maven, IntelliJ, MySQL Studio, Postman, Chrome Dev tools, Figma, VS Studio Code, Unix/Linux basics
                </h4>
            </div>
            </div>


                    <div className="cv-job-container" id="work1">
                        <div className="cv-job-content">

                        <h2 className="cv-work-experience-company-name">
                            Tiffany and Co.
                        </h2>
                        <h4 className="cv-work-experience-job-title">
                            Precious Metals Caster
                        </h4>
                        <h4 className="cv-work-experience-job-date">
                            April 2021 - PRESENT
                        </h4>
                        <text className="cv-work-experience-job-summary">
                            My job is to operate and maintain gold/platinum casting machines
                            and burn out ovens in support of the jewelry manufacturing operations.
                            Also responsible for supporting processes like investment/divestment of castings,
                            and quality inspections under microscope.
                        </text>
                        </div>
                    </div>

                    <div className="cv-job-container" id="work2">
                        <div className="cv-job-content">
                        <h2 className="cv-work-experience-company-name">
                            Fourstar Connections
                        </h2>
                        <h4 className="cv-work-experience-job-title">
                            Lead Technician - Injection Molding Department
                        </h4>
                        <h4 className="cv-work-experience-job-date">
                            November 2020 - April 2021
                        </h4>
                        <text className="cv-work-experience-job-summary">
                            I was responsible for the department and assigning tasks to operators,
                            set-up of machines for the operators and ensuring machines operated as expected during production.
                            A large part of the job was creating process documentation and collaborating with
                            the machine shop to modify or engineer and create new tooling as needed.
                            Other duties were developing and testing new product manufacturing processes,
                            as well as maintaining and repairing the machines.
                        </text>
                    </div>
                    </div>

                    <div className="cv-job-container" id="work3">
                        <div className="cv-job-content">

                        <h2 className="cv-work-experience-company-name">
                            Zero Porosity Casting
                        </h2>
                        <h4 className="cv-work-experience-job-title">
                            Molding and Injections Department Manager
                        </h4>
                        <h4 className="cv-work-experience-job-date">
                            April 2016 - June 2020
                        </h4>
                        <text className="cv-work-experience-job-summary">
                            I was in charge of the molding and wax injections department,
                            in support of casting operations.
                            During my time there I conceived and implemented a new mold inventory storage system to improve
                            inter-departmental communication, as well as a new chain of custody system for customer
                            owned molds to avoid loss or damage of their property.
                        </text>
                        </div>
                    </div>




        </div>
    </div>

}