import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CVPage from "./Pages/CV-page";
import ProjectsPage from "./Pages/Projects-page";
import "./app.css";
export default function App() {

    let Component
//this switch detects which path is being used by the client
// and displays the correct component
    switch (window.location.pathname) {
        case "/" :
            Component = CVPage
            break
        case "/cv" :
            Component = CVPage
            break
        case "/projects" :
            Component = ProjectsPage
            break
        default : Component = CVPage
    }
//need to do some grid or flexbox stuff to make sure footer is under component
    return (
        <div className="app-container">
        <Navbar className="app-nav-bar" />
        <Component className="app-component"/>

        </div>
)
}

